import React, { lazy } from 'react';

import createRoute from '@/core/Router/createRoute';
import Logout from '@/core/Logout';

const Home        = lazy(() => import('@/core/Home')),
	  UserDetails = lazy(() => import('@/core/UserDetails')),
	  Invoices    = lazy(() => import('@/core/Invoices'));


export let routes = [

	createRoute({
		path:    '/logout',
		exact:   true,
		render:  <Logout />,
		title:   'Log Out',
		private: true,
	}),

	createRoute({
		path:    '/user-details',
		exact:   true,
		render:  <UserDetails />,
		title:   'User Details',
		private: true,
	}),

	createRoute({
		path:    '/invoices',
		exact:   true,
		render:  <Invoices />,
		title:   'Invoices',
		private: true,
	}),


	createRoute({
		path:    '/',
		exact:   true,
		render:  <Home />,
		title:   'Portal Home',
		private: true,
	}),

];


function registerRoutes(newRoutes, location = 'start') {
	if(location === 'start') {
		routes = [ ...newRoutes.map(route => createRoute(route)), ...routes ];
	} else {
		routes = [ ...routes, ...newRoutes.map(route => createRoute(route)) ];
	}
}

export { registerRoutes };
