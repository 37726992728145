import Cookies from 'js-cookie';

const portalCookie = Cookies.withAttributes({
	sameSite: 'None',
	path:   import.meta.env.VITE_COOKIE_BASE_PATH,
	domain: import.meta.env.VITE_COOKIE_BASE_DOMAIN,
	secure: import.meta.env.VITE_SECURE_COOKIE === 'TRUE',
});


export default portalCookie;