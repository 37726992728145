import { HomeOutlined, LogoutOutlined, TransactionOutlined, UserOutlined } from '@ant-design/icons';

import React from 'react';

let homeEntry = {
	title:  'Home',
	single: true,
	top:    true,
	home:   false,
	url:    '/',
	icon:   <HomeOutlined />,
};

const requiredEntries = [
	{
		title:       'Invoices',
		permissions: [],
		single:      false,
		top:         true,
		home:        true,
		icon:         <TransactionOutlined />,
		children: [
			{
				title:       'Invoices',
				url:         '/invoices',
				icon:        <TransactionOutlined />,
				permissions: [],
			}, 
		]
	},{
		title:       'My Account',
		permissions: [],
		single:      false,
		top:         true,
		home:        true,
		icon:        <UserOutlined />,
		children:    [
			{
				title:       'User Details',
				url:         '/user-details',
				icon:        <UserOutlined />,
				permissions: [],
			}, 
			{
				title:       'Log Out',
				url:         '/logout',
				icon:        <LogoutOutlined />,
				permissions: [],
			},

		],
	},
];

export let navigation = [];





function formatNavigation(config) {
	let nav = [
		homeEntry,
		...config,
		...requiredEntries,
	];

	return nav.map(row => ({
		title:       'Default Title',
		single:      false,
		icon:        null,
		top:         true,
		home:        true,
		...row,
		permissions: Array.isArray(row.permissions)
			? row.permissions
			: [],
		children: (row.children || []).map(child => ({
			...child,
			permissions: Array.isArray(child.permissions)
				? child.permissions
				: [],
		})),
		routes: (row.children || []).map(child => child.url),
	}));
}


function registerNavigation(navEntry) {
	let navIndex = navigation.findIndex(row => row.title === navEntry.title);

	if(navIndex === -1) {
		navigation.push(navEntry);
	} else {
		navigation[navIndex] = navEntry;
	}
}

export { registerNavigation };

let formattedNavigation = () => (formatNavigation(navigation));

export default formattedNavigation;
