import React, { useCallback, useEffect } from 'react';
import { Button, Card, Col, Divider, Row, Typography } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Cookies from '@/portal-cookie';
import { useDispatch } from 'react-redux';
import { actions } from '@/core/store';

const Logout = () => {

	const dispatch = useDispatch();
	const setAuth = useCallback((data) => {
		dispatch(actions.auth.setState(data));
	}, [ dispatch ]);

	useEffect(() => {
		Cookies.remove('authToken');
		setAuth({ loggedIn: false, pending: false });
	}, [ setAuth ]);

	return (
		<Row className="ts_home_content_container">
			<Col xs={24}>
				<Card title={<div><LogoutOutlined /> Log Out</div>} className="ts_content_container ts_portal_card">
					<Typography>
						You have been successfully logged out.  Please click below if you would
						like to log in again.
					</Typography>
					<Divider
						style={{
							borderColor: '#7cb305',
						}}
					>
						<Button
							type={'primary'}
						>
							<Link to="/">Back to Login</Link>
						</Button>
					</Divider>
				</Card>
			</Col>
		</Row>

	);
};


export default Logout;
