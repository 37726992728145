import api from '@/api';
import base64toPDF from '@/lib/base64ToPDF';
import Cookies from '@/portal-cookie';
import { message } from 'antd';

const initialState = {
	drafts: [],
	cardCode: '',
	loading: false,
	loaded:  false,
	loadedCardCode: false,
	showInvoicePaymentDialog: false,
	showPreviewPDFDialog: false,
	pdfModalLoading: false,
	pdfModalKey: null,
	pdfModalData: null,
	pdfFilePreview: null,
};

export const types = {
	SET_STATE: 'DRAFT/SET_STATE',
};

const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
};

export default reducers;

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),
	
	loadDrafts: () => async (dispatch, getState) => {
		const state    = getState(),
			loading  = state.drafts.loading,
			cardCode = state.drafts.cardCode;

		if(loading) {
			return false;
		}

		dispatch(actions.setState({ loading: true, loaded: false }));
		try {
			let response = await api.get('/drafts', {
				params: {
					cardCode,
				},
			});

			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loaded: true,
					drafts: response,
				},
			});
		} catch(err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loaded: true,
					drafts: [],
				},
			});
		}
	},

	loadCardCode: () => async (dispatch, getState) => {
		try {
			const userKey = Cookies.get('TS_UserKey');
			const response = await api.get('/invoices/vendor-code', {
				params: {
					userKey: userKey,
				},
			});



			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loadedCardCode:  true,
					cardCode: response.BPCode,
				},
			});
		} catch(err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loadedCardCode:  true,
					cardCode: '',
				},
			});
		}
	},

};
